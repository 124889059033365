<template>
  <div class="blog relative landing-page-wrapper new-langing-ui">
    <landing-page-header
      class="customer-landing-page-header"
      :nav-class="'light'"
    />
    <content-with-sidebar class="blog-wrapper content-info no-white-space">
      <!-- blogs -->
      <b-row
        v-if="blog"
        class="blog-list-wrapper"
      >
        <b-col md="12">
          <b-card
            tag="article"
            class="articles-wrapper"
            no-body
          >
            <b-link>
              <b-img
                :src="$helpers.imageHelper(blog.picture)"
                class="card-img-top img-details-blog"
                @error="$helpers.imageError"
              />
            </b-link>
            <b-card-body>
              <b-card-title class="d-flex align-items-center justify-content-between">
                <b-link
                  class="text-body-heading"
                  :class="isMobile ? 'font-20' : 'font-35'"
                >
                  {{ blog.title }}
                </b-link>
              </b-card-title>
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-50"
                >
                  <b-avatar
                    href="javascript:void(0)"
                    size="24"
                    :src="$helpers.imageHelper(blog.user_picture)"
                  />
                </b-media-aside>
                <b-media-body>
                  <small
                    v-if="blog.user_fullname"
                    class="text-muted mr-50"
                  >{{ $t('by') }}</small>
                  <small v-if="blog.user_fullname">
                    <b-link
                      class="text-body"
                      :class="{ 'font-18': !isMobile }"
                    >{{ blog.user_fullname }}</b-link>
                  </small>
                </b-media-body>
              </b-media>
              <div
                v-if="blog.tags"
                class="my-1 py-25"
              >
                <b-link
                  v-for="(tag, count) in blog.tags"
                  :key="count"
                >
                  <b-badge
                    pill
                    class="mr-75"
                    :class="{ 'font-18' : !isMobile }"
                    style="margin-bottom: 7px;"
                    :variant="tagsColor(tag)"
                  >
                    {{ tag }}
                  </b-badge>
                </b-link>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <b-card-text
                class="mb-2 mt-2"
                :class="{ 'font-20' : !isMobile }"
                v-html="blog.description"
              />
              <!--eslint-enable-->
              <!-- <hr> -->
              <div class="d-flex align-items-center">
                <b-link class="mx-1">
                  <div class="d-flex align-items-center text-body">
                    <feather-icon
                      icon="MessageSquareIcon"
                      class="mr-50"
                    />
                    <span class="font-weight-bold">{{ kFormatter(blog.comments_count) }} {{ $t('Comments') }}</span>
                  </div>
                </b-link>
                <b-link class="mx-1">
                  <div
                    class="d-flex align-items-center text-body"
                    @click="likeClicked(blog)"
                  >
                    <feather-icon
                      icon="ThumbsUpIcon"
                      class="mr-50"
                    />
                    <span class="font-weight-bold">{{ kFormatter(blog.likes ? blog.likes : 0) }} {{ $t('Likes') }}</span>
                  </div>
                </b-link>
                <b-link class="mx-1">
                  <div
                    class="d-flex align-items-center text-body"
                    @click="shareClicked(blog)"
                  >
                    <feather-icon
                      icon="Share2Icon"
                      class="mr-50"
                    />
                    <span class="font-weight-bold">{{ kFormatter(blog.shares) }} {{ $t('Shares') }}</span>
                  </div>
                </b-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="commentList && commentList.length > 0"
          id="blogComment"
          cols="12"
          class="mt-2"
        >
          <h6 class="section-label">
            Comment
          </h6>
          <b-card
            v-for="(comment, index) in commentList"
            :key="index"
          >
            <b-media no-body>
              <b-media-aside class="mr-75">
                <img
                  width="38px"
                  class="rounded-circle"
                  :src="$helpers.imageHelper(comment.user ? comment.user.picture : '')"
                  @error="$helpers.imageError"
                >
              </b-media-aside>
              <b-media-body>
                <h6 class="font-weight-bolder mb-25">
                  {{ comment.user ? `${comment.user.first_name} ${comment.user.last_name}` : '' }}
                </h6>
                <b-card-text>{{ formatDate(comment.created_at) }}</b-card-text>
                <b-card-text>
                  {{ comment.comment }}
                </b-card-text>
                <b-card-text v-if="comment.comment_response">
                  Reply at {{ formatDate(comment.comment_response.created_at) }}: {{ comment.comment_response.comment }}
                </b-card-text>
                <b-link
                  v-if="isLogged"
                  href="javascript: void(0);"
                  @click="doReply(comment)"
                >
                  <div class="d-inline-flex align-items-center">
                    <feather-icon
                      icon="CornerUpLeftIcon"
                      size="18"
                      class="mr-50"
                    />
                    <span>Reply</span>
                  </div>
                </b-link>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
        <b-col
          v-if="!isLogged"
          cols="12"
          class="mt-2"
        >
          <h3 class="section-label">
            {{ $t('Please login in order to post a comment') }}
          </h3>
          <b-button
            variant="primary"
            tag="a"
            :to="{
              path: '/user/login',
              query: {
                returnUrl: $route.path,
              },
            }"
          >
            Login
          </b-button>
        </b-col>
        <b-col
          v-else
          id="leaveCommentSection"
          cols="12"
          class="mt-2"
        >
          <h6 class="section-label">
            Leave a Comment
          </h6>
          <b-card>
            <b-form>
              <b-row>
                <b-col
                  v-if="replyComment"
                  sm="12"
                >
                  <h4>
                    Reply for: {{ replyComment.comment }} by <span class="font-weight-bolder">{{ replyComment.user ? `${replyComment.user.first_name} ${replyComment.user.last_name}` : '' }}</span>
                    <span
                      class="text-danger ml-2 cursor-pointer"
                      @click="replyComment = null"
                    >Cancel</span>
                  </h4>
                </b-col>
                <b-col cols="12">
                  <b-form-group class="mb-2">
                    <b-form-textarea
                      v-model="comentInfo.comment"
                      name="textarea"
                      rows="4"
                      placeholder="Comment..."
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    @click="addComment"
                  >
                    Post Comment
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>

      <!--/ blogs -->

      <!-- sidebar -->
      <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
      >
        <!-- input search -->
        <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search-input"
              v-model="search_query"
              placeholder="Search here"
            />
            <b-input-group-append
              class="cursor-pointer"
              is-text
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!--/ input search -->

        <!-- recent posts -->
        <div class="blog-recent-posts mt-1">
          <h6 class="section-label mb-75">
            {{ $t('Recent Posts') }}
          </h6>
          <b-media
            v-for="(recentpost, index) in blogRecentPost"
            :key="index"
            no-body
            :class="index ? 'mt-2' : ''"
          >
            <b-media-aside class="mr-2">
              <b-link :to="{ path: getViewUrl(recentpost) }">
                <b-img
                  :src="$helpers.imageHelper(recentpost.picture)"
                  width="100"
                  rounded
                  height="70"
                  @error="$helpers.imageError"
                />
              </b-link>
            </b-media-aside>
            <b-media-body>
              <h6 class="blog-recent-post-title">
                <b-link
                  :to="{ path: getViewUrl(recentpost) }"
                  class="text-body-heading"
                >
                  {{ recentpost.title }}
                </b-link>
              </h6>
              <span class="text-muted mb-0">
                {{ formatDate(recentpost.created_at) }}
              </span>
            </b-media-body>
          </b-media>
        </div>
        <!--/ recent posts -->
      </div>
      <!--/ sidebar -->
    </content-with-sidebar>
    <landing-page-footer />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BButton,
  BForm,
  BInputGroupAppend,
} from 'bootstrap-vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import moment from 'moment'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { isUserLoggedIn } from '@/auth/utils'
import { useUserUi } from '../users/useUser'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormTextarea,
    BForm,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BImg,
    ContentWithSidebar,
    LandingPageHeader,
    LandingPageFooter,
  },
  data() {
    return {
      showPagination: false,
      search_query: '',
      blogList: [],
      blogRecentPost: [],
      blogSidebar: {},
      commentList: [],
      comentInfo: {
        comment: '',
        title: '',
      },
      currentPage: 1,
      blog: {},
      perPage: 1,
      rows: 140,
      replyComment: null,
    }
  },
  computed: {
    isLogged() {
      return isUserLoggedIn()
    },
    isMobile() {
      return this.$store.getters['app/windowSize'] <= 767
    },
  },
  watch: {
    '$route.params.blogId': {
      handler() {
        this.blogListById()
      },
    },
  },
  mounted() {
    this.getLatestBlogList()
    this.blogListById()
    this.fetchBlogComments()
  },
  methods: {
    likeClicked(blog) {
      const { likeBlog } = useUserUi()
      showLoader()
      likeBlog(blog.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (!blog.likes) {
              // eslint-disable-next-line no-param-reassign
              blog.likes = 0
            }
            // eslint-disable-next-line no-param-reassign
            blog.likes += 1
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    shareClicked(blog) {
      window.SuperApp.actions.sharePage('MOM', blog.title, this.getViewUrl(blog))
      const { shareBlog } = useUserUi()
      shareBlog(blog.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            // eslint-disable-next-line no-param-reassign
            blog.shares += 1
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm A')
    },
    kFormatter,
    doReply(comment) {
      this.replyComment = comment
      window.SuperApp.helper.scrollTo('leaveCommentSection')
    },
    fetchBlogComments() {
      const { fetchBlogComments } = useUserUi()

      fetchBlogComments(`${this.$route.params.blogId}&page=all`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.commentList = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    tagsColor() {
      return 'light-primary'
    },
    getViewUrl(blog) {
      return `/blog/${blog.title.trim().toLowerCase().replace(/ /g, '-').replace(/[^a-zA-Z- ]/g, '')}/${blog.id}`
    },
    blogListById() {
      const { blogListById } = useUserUi()
      showLoader()
      blogListById(this.$route.params.blogId)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.blog = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getLatestBlogList() {
      const { getAllBlogs } = useUserUi()
      showLoader()
      getAllBlogs()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.blogList = data.responseData
            this.blogRecentPost = window.SuperApp.actions.cloneDeep(this.blogList).reverse().slice(0, 10)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    addComment() {
      const formData = new FormData()
      formData.append('status', 1)
      formData.append('blog_id', this.$route.params.blogId)
      formData.append('user_id', window.SuperApp.getters.userInfo().id)
      formData.append('comment', this.comentInfo.comment)
      if (this.replyComment) {
        formData.append('parent_id', this.replyComment.id)
      }
      const { addBlogComments } = useUserUi()
      showLoader()
      addBlogComments(formData)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            this.fetchBlogComments()
            this.comentInfo = {
              comment: '',
              title: '',
            }
            this.replyComment = null
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
.blog .cws-container .cws-sidebar {
  top: 2rem;
  right: 15px;
}
</style>

<style scoped>
.landing-page-wrapper .content-info {
  padding-top: 2rem;
  padding-left: 10px;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing-page.scss';
</style>

<style lang="scss" scoped>
.img-details-blog {
  object-fit: fill;

  @media only screen and (max-width: 767px) {
    height: auto;
  }
}
</style>
